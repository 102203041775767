<template>
    <SectionLayout>
        <v-col cols="12" class="ma-0 pa-0">
        <v-row justify="center" class="pt-10">
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div class="pa-5 text-center" v-if="loading">
                    <p class="display-1 font-weight-light">Loading...</p>
                </div>
                <div class="pa-5 text-center" v-if="error">
                    <p class="headline font-weight-light red--text">We cannot process this request.</p>
                </div>
                <div class="pa-5 text-center" v-if="errorUnknownInteraction">
                    <p class="headline font-weight-light red--text">We cannot process this request.</p>
                </div>
                <div class="pa-5 text-center" v-if="errorUnauthorizedInteraction">
                    <v-row justify="center">
                        <p class="headline font-weight-light red--text">Unauthorized</p>
                    </v-row>
                    <v-row justify="center">
                        <p class="headline font-weight-light">Did you start in another browser? Try opening the link in that browser, or start over in this one.</p>
                    </v-row>
                    <v-row justify="center">
                        <p class="body-1 font-weight-light text-center"><router-link to="/">Start over</router-link></p>
                    </v-row>
                </div>
            </v-col>
        </v-row>
        </v-col>
    </SectionLayout>
</template>

<style scoped>
a, a:visited {
    color: #00f;
}
a:hover {
    color: #00f;
    text-decoration: underline;
}
</style>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { Client } from '@/client';
import SectionLayout from '@/components/SectionLayout.vue';

const client = new Client();

export default {
    components: {
        SectionLayout,
    },
    data() {
        return {
            token: null,
            interactionId: null,
            isViewReady: false,
            loading: true,
            error: false,
            errorUnknownInteraction: false,
            errorUnauthorizedInteraction: false,
        };
    },
    methods: {
        init() {
            console.log('interaction.vue: init; isAuthenticated: %o', this.isAuthenticated);
            if (this.token) {
                this.useInteractionToken();
            } else if (this.interactionId) {
                this.fetchInteractionInfo();
            }
        },
        createAccount({ state }) {
            const { isVerified, isExisting } = state;
            if (isExisting) {
                this.$router.replace({ path: '/create-account/conflict', query: { i: this.interactionId } });
                return;
            }
            if (isVerified) {
                this.$router.replace({ path: '/create-account/login', query: { i: this.interactionId } });
                return;
            }
            this.errorUnknownInteraction = true;
        },
        recoverAccess({ state }) {
            const { isVerified } = state;
            if (isVerified) {
                this.$router.replace({ path: '/recover-access', query: { i: this.interactionId } });
                return;
            }
            this.errorUnknownInteraction = true;
        },
        async useInteractionToken() {
            try {
                this.$store.commit('loading', { useInteractionToken: true });
                const response = await client.interaction.resumeWithToken(this.token);
                console.log(`useInteractionToken: response ${JSON.stringify(response)}`);
                if (response.interactionId) {
                    this.$router.replace({ path: '/interaction', query: { i: response.interactionId } }); // so that a reload won't attempt to use the token again
                    this.interactionId = response.interactionId;
                    await this.fetchInteractionInfo();
                } else if (response.error === 'unauthorized') {
                    this.errorUnauthorizedInteraction = true;
                } else {
                    this.error = true;
                }
            } catch (err) {
                // err object looks like { message, name, stack, config: { url, method, params, headers, timeout }, response: { data, headers, status }}
                if (err.response && err.response.status === 401) {
                    this.errorUnauthorizedInteraction = true;
                } else {
                    this.error = true;
                }
            } finally {
                this.loading = false;
                this.$store.commit('loading', { useInteractionToken: false });
            }
        },
        async fetchInteractionInfo(prevInteraction = {}) {
            this.$store.commit('loading', { loadInteraction: true });
            const { type, next, state } = await client.interaction.get(this.interactionId);
            this.loading = false;
            console.log(`interaction.vue: fetchInteractionInfo: interaction type: ${type} next: ${next}`);
            switch (type) {
            case 'create_account':
                this.createAccount({ type, next, state }, prevInteraction);
                break;
            case 'recover_access':
                this.recoverAccess({ type, next, state }, prevInteraction);
                break;
            default:
                console.error('interaction.vue: unknown interaction type: %s', type);
                this.errorUnknownInteraction = true;
                break;
            }
            this.$store.commit('loading', { loadInteraction: false });
        },
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    watch: {
        isReady(value, oldValue) {
            console.log('interaction.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    created() {
        if (this.$route.query.error) {
            console.log(`interaction.vue: created, error: ${this.$route.query.error}`);
            this.loading = false;
            switch (this.$route.query.error) {
            case 'unauthorized':
                this.errorUnauthorizedInteraction = true;
                break;
            default:
                this.errorUnknownInteraction = true;
            }
        } else {
            this.token = this.$route.query.token;
            this.interactionId = this.$route.query.i;
            console.log(`interaction.vue: created, token ${this.token} interactionId ${this.interactionId}`);
        }
    },
    mounted() {
        console.log('interaction.vue: mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
